import Api from '@/api'
import { intersection } from 'lodash'
// state
export const state = {
  user: null
}

// getters
export const getters = {
  user: state => state.user,
  check: state => state.user !== null,
  verified: state => state.user && state.user.email_verified_at !== null,
  allEducation: (state, getters ) => {
    if (!state.user) return false
    return getters.educations && getters.educations[0]['stage'] == 'All'
  },
  educations: state => {
    if (!state.user) return []
    return state.user.education_permissions
  },
  allProvince: (state, getters ) => {
    if (!state.user) return false
    return getters.provinces && getters.provinces[0]['kd_prop'] == 'All'
  },
  provinces: state => {
    if (!state.user) return []
    return state.user.province_permissions
  },
  allCity: (state, getters ) => {
    if (!state.user) return false
    return getters.cities && getters.cities[0]['kd_kabkota'] == 'All'
  },
  cities: state => {
    if (!state.user) return []
    return state.user.city_permissions
  },
  allFund: (state, getters ) => {
    if (!state.user) return false
    return getters.funds && getters.funds[0]['kd_sumber_dana'] == 'All'
  },
  funds: state => {
    if (!state.user) return []
    return state.user.fund_permissions
  },
  hasRole: (state) => role => {
    if (!state.user?.active) return false
    let isSuper = state.user.roles.includes('*')
    let roles = Array.isArray(role) ? role : [role]
    let isAllowed = intersection(state.user.roles, roles).length > 0
    return isSuper || isAllowed
  }
}

// mutations
export const mutations = {
  FETCH_USER_SUCCESS (state, { data }) {
    state.user = data
  },

  CLEAR_USER (state) {
    state.user = null
  },

  UPDATE_USER (state, { user }) {
    state.user = user
  }
}

// actions
export const actions = {
  async fetchUser ({ commit, state }) {
    if (state.user) return
    try {
      const response = await Api.auth.check()

      if (response.status === 200) {
        commit('FETCH_USER_SUCCESS', { data: response.data })
      } else {
        commit('CLEAR_USER')
      }
    } catch (e) {
      commit('CLEAR_USER')
    }
  },

  updateUser ({ commit }, payload) {
    commit('UPDATE_USER', payload)
  },

  async logout ({ commit }) {
    await Api.auth.logout()
    commit('CLEAR_USER')
  },

  clearUser ({ commit }) {
    commit('CLEAR_USER')
  }
}
