import axios from '@/plugins/axios'
import store from '@/store'

export default {
  async sso (code) {
    return axios.post('/inspector/login', {code})
  },
  check () {
    return axios.get('/inspector/check')
  },
  async logout () {
    await axios.post('/inspector/logout')
    store.dispatch('auth/clearUser')
  },
  verifyEmail(id, hash, query) {
    return axios.get(`/inspector/inspector-email/verify/${id}/${hash}?${query}`)
  },
  resendEmailVerify() {
    return axios.post('/inspector/inspector-email/verification-notification')
  }
}
