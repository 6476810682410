export default [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'home',
    meta: {
      guard: 'auth',
    },
    component: () => import('./views/home'),
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('./views/maintenance'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      guard: 'auth',
    },
    component: () => import('./views/profile'),
  },
  {
    path: '/sso',
    name: 'sso',
    component: () => import('./views/account/sso'),
    meta: {
      guard: 'guest',
    },
  },
  {
    path: '/inspector-email/verify',
    name: 'verify-email',
    component: () => import('./views/account/verify'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/errors/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/pages/500',
    name: 'Error-500',
    meta: { guard: 'auth' },
    component: () => import('./views/errors/500')
  },
  // Orders
  {
    path: '/orders',
    name: 'orders',
    meta: { guard: 'auth' },
    component: () => import('./views/orders/index')
  },
  {
    path: '/closing',
    name: 'closing',
    meta: { guard: 'auth' },
    component: () => import('./views/closing/index')
  },
  {
    path: '/orders/:orderId',
    name: 'orders-details',
    meta: { guard: 'auth' },
    component: () => import('./views/orders/details')
  },
  // Schools
  {
    path: '/schools',
    name: 'schools',
    meta: { guard: 'auth' },
    component: () => import('./views/schools/index')
  },
  {
    path: '/schools/:schoolId',
    name: 'schools-details',
    meta: { guard: 'auth' },
    component: () => import('./views/schools/details')
  },
  // Merchants
  {
    path: '/merchants',
    name: 'merchants',
    meta: { guard: 'auth' },
    component: () => import('./views/merchants/index')
  },
  {
    path: '/merchants/:merchantId',
    name: 'merchants-details',
    meta: { guard: 'auth' },
    component: () => import('./views/merchants/details')
  },
  {
    path: '/products',
    name: 'products',
    meta: { guard: 'auth' },
    component: () => import('./views/products/index')
  },
  {
    path: '/products/:productId',
    name: 'products-details',
    meta: { guard: 'auth' },
    component: () => import('./views/products/details')
  },
  // complains
  {
    path: '/complains',
    name: 'complains',
    meta: { guard: 'auth' },
    component: () => import('./views/complains/index')
  },
  {
    path: '/inspectors',
    name: 'inspectors',
    meta: { guard: 'auth' },
    component: () => import('./views/admin/inspectors')
  },
  {
    path: '/product-categories',
    name: 'product-categories',
    meta: { guard: 'auth' },
    component: () => import('./views/admin/category')
  },
  {
    path: '/log-activities',
    name: 'log-activities',
    meta: { guard: 'auth' },
    component: () => import('./views/admin/user-activity')
  },
]
