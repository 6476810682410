import axios from '@/plugins/axios'

export default {
  index (query = '') {
    return axios.get(`/inspector/merchants${query}`)
  },
  details(merchantId) {
    return axios.get(`/inspector/merchants/${merchantId}`)
  },
}
