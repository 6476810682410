import axios from '@/plugins/axios'

export default {
  index (query = '') {
    return axios.get(`/inspector/schools${query}`)
  },
  details(schoolId) {
    return axios.get(`/inspector/schools/${schoolId}`)
  },

}
