import axios from '@/plugins/axios'
import { savePost } from '../utils'

export default {
  index (query = '') {
    return axios.get(`/inspector/orders${query}`)
  },
  details(orderId) {
    return axios.get(`/inspector/orders/${orderId}`)
  },
  historiesByOrder(orderId) {
    return axios.get(`/inspector/orders/${orderId}/histories`)
  },
  // close order
  closing(query = '') {
    return axios.get(`/inspector/close-orders${query}`)
  },
  closingAction(closeOrderId, type, payload) {
    return savePost(`/inspector/close-orders/${closeOrderId}/${type}`, payload)
  },
}
