import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import router from './router/index'

import "@/design/index.scss"
import store from '@/store'

import App from './App.vue'

import TableCategory from './components/global/TableCategory'

Vue.config.productionTip = false

Vue.use(VueRouter)

Vue.use(BootstrapVue)

Vue.component('TableCategory', TableCategory);

Vue.filter('shortNumber', function (value) {
  var floor = value = Math.floor(value)
  if (floor < 1000) return value
  function shorten(a, b, c) {
      let d = a.toString()
      let first =  d.substring(0, d.length - c)
      return first + b
  }
  if ((floor/1e9) >= 1) return shorten(floor, 'B', 9)
  if ((floor/1e6) >= 1) return shorten(floor, 'M', 6)
  if ((floor/1e3) >= 1) return shorten(floor, 'K', 3)
})

Vue.filter('number', function (value) {
  if (value) {
    return parseInt(value).toLocaleString('id-ID')
  }
  return 0
})

Vue.filter('localDate', function (value) {
  if (value) {
    return (new Date(value)).toLocaleString('id-ID')
  }
  return ''
})

Vue.filter('staticFile', function (value) {
  if (!value) return ''
  let isHttp = value.substring(0, 4).toLowerCase() == 'http'
  if (isHttp) {
    return value
  }
  return process.env.VUE_APP_BASE_STATIC + value
})

Vue.filter('siplahLink', function (value) {
  if (!value) return ''
  return process.env.VUE_APP_SIPLAH + '/' +value
})

Vue.filter('truncate', function(text, length, clamp){
  clamp = clamp || ' ...'
  var node = document.createElement('div')
  node.innerHTML = text
  var content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
})

Vue.filter('uppercase', function(text) {
    if(!text) return ''
    let temp = text.toString()
    return temp.toUpperCase()
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
