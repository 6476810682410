import axios from '@/plugins/axios'

export default {
  index (query = '') {
    return axios.get(`/inspector/complains${query}`)
  },
  details(complainId) {
    return axios.get(`/inspector/complains/${complainId}`)
  },
}
