import axios from '@/plugins/axios'

export default {
  index () {
    return axios.get(`/inspector/dashboard/index`)
  },
  getTransactions (orderDate = '', source = '', location = '') {
    return axios.get(`/inspector/dashboard/transactions?order_date=${orderDate}&source=${source}&location=${location}`)
  },
  getSchools(location = '') {
    return axios.get(`/inspector/dashboard/schools?location=${location}`)
  },
  getMerchants(status = '', location = '') {
    return axios.get(`/inspector/dashboard/merchants?status=${status}&location=${location}`)
  },
  getProducts (status = '') {
    return axios.get(`/inspector/dashboard/products?status=${status}`)
  },
  getLogActivities(query = '') {
    return axios.get(`/inspector/dashboard/log-activities${query}`)
  },
}
