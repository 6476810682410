import axios from '@/plugins/axios'

export default {
  maintenance() {
    return axios.get('/ping')
  },
  getSystemBanks () {
    return axios.get('/public/system-banks')
  },
  getBanks () {
    return axios.get('/public/banks')
  },

  getProvinces () {
    return axios.get('/public/provinces')
  },
  getCities (provinceId) {
    return axios.get(`/public/cities/${provinceId}`)
  },
  getDistricts (cityId) {
    return axios.get(`/public/districts/${cityId}`)
  }
}
